<template>
  <div class="details">
    <div class="title" style="margin: -20px 0 0 0 !important;">{{data.CName}}</div>
<!--    <viewer :images="data.imgs">-->
      <div style="display: flex;overflow: auto;">
        <img v-for="(src,index) in data.imgs" :src="src" :key="src" height="120" :class="['img', {'active': activeIndex == index}]" @click="activeIndex = index">
      </div>
<!--    </viewer>-->
    <div style="text-align: center;margin-top: 10px;">
      <img style="width: 640px;border: 1px solid #b7b7b7;" :src="data.imgs[activeIndex]">
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import mixins from '../../mixins'
  export default {
    name: "AtlasPictureDetails",
    mixins: [
      mixins
    ],
    data(){
      return {
        data: {
          imgs: []
        },
        activeIndex:0
      }
    },
    created() {
      if (this.$route.query.id || this.$route.query.ID){
        axios({
          methods: 'get',
          url: 'http://med-wiki.xenpie.com/api/',
          params: { id: this.$route.query.id || this.$route.query.ID, type: '7'}
        }).then(res => {
          console.log('===>', res)
          if (res.status == 200){
            let data = res.data.data.detail
            data.imgs = data.img.replace(/http:\/\/211\.103\.242\.133:8080/g,'https://med-wiki.cos.xenpie.com').split(',')
            this.data = data
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .img{
    margin: 0px 0px 0 0;
    border: 1px solid #b7b7b7;
  }

  .active{
    border: 1px solid #409EFF !important;
  }
</style>
